import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['filterTab', 'all', 'algorithmStage', 'finalSubmissions', 'submissionNeeded', 'newUpdates', 'actionNeeded', 'invitationPending', 'settled', 'inactive', 'didntSettle', 'allBlock', 'finalSubmissionsBlock', 'submissionNeededBlock']

  toggleActive(e) {
    this.filterTabTargets.forEach((filterTab) => {
      if (filterTab.classList.contains("active")) {
        filterTab.classList.remove("active")
      }
    })
    const filterValue = e.currentTarget.dataset.filterValue
    this[`${filterValue}Target`].classList.add("active")

    const capitalizedFilterValue = filterValue.charAt(0).toUpperCase() + filterValue.substring(1)
    if (this[`has${capitalizedFilterValue}BlockTarget`]) {
      this[`${filterValue}BlockTarget`].classList.add("active")
    }
  }
}
